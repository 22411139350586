/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sidebar.component";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 245760, null, 0, i1.SidebarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i0.ɵccf("app-sidebar", i1.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
