import { LOCAL_VAR } from './../../../core/constants';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { STATES } from '@app/core/constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/core/service/users.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  userCreateForm: FormGroup;
  isLoading = false;
  queryParams: any;
  states: any = STATES;
  selectedAgent: any = null;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private toastrService: ToastrService
  ) {
    this.selectedAgent = localStorage.getItem(LOCAL_VAR.selectedAgent);
    this.selectedAgent = JSON.parse(this.selectedAgent);
    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
      this.createForm();
    });
  }

  ngOnInit() {}
  private createForm() {
    this.userCreateForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['AL', [Validators.required]],
      zipcode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])]
    });
  }

  createUser() {
    this.isLoading = true;
    this.usersService
      .createAgentUser(this.selectedAgent._id, this.userCreateForm.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (response: any) => {
          this.activeModal.close(response);
        },
        (error: any) => {
          console.log(
            'Confirmation modal error:',
            error.error.validation.errors
          );
          this.isLoading = false;
        }
      );
  }
}
