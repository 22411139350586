import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UsersService = /** @class */ (function () {
    function UsersService(_httpClient) {
        this._httpClient = _httpClient;
    }
    UsersService.prototype.getAgentUsers = function (agentId) {
        return this._httpClient.get("/agent/" + agentId + "/users").pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getUsers = function (userId) {
        return this._httpClient.get("/user/" + userId + "/users").pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getFilterUsers = function (userId, payload) {
        return this._httpClient.put("/user/" + userId + "/users", payload).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getUserDetails = function (userId) {
        return this._httpClient.get("/user/" + userId).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getUserDetail = function (agentId, userId) {
        return this._httpClient.get("/agent/" + agentId + "/user/" + userId).pipe(map(function (body) {
            return body;
        }));
    };
    //todo - remove later
    UsersService.prototype.createAgentUser = function (agentId, payload) {
        return this._httpClient.post("/agent/" + agentId + "/user", payload).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.createUser = function (userId, payload) {
        return this._httpClient.post("/agent/" + userId + "/user", payload).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.updateAgentUser = function (agentId, userId, payload) {
        return this._httpClient
            .put("/agent/" + agentId + "/user/" + userId, payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.deleteAgentUser = function (agentId, userId) {
        return this._httpClient.delete("/agent/" + agentId + "/user/" + userId).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getUserRoles = function () {
        return this._httpClient.get("/getuserroles").pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.sendActivationCode = function (userId) {
        return this._httpClient.get("/agent/" + userId + "/sendcode").pipe(map(function (body) {
            return body;
        }));
    };
    //audit
    UsersService.prototype.getAuditActions = function () {
        return this._httpClient.get("/auditactions").pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getAllAudits = function (payload) {
        return this._httpClient.get("/audit", payload).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getAllAuditsByFilter = function (payload) {
        var params = payload.customerId
            ? new HttpParams().set('customerId', "" + payload.customerId)
            : new HttpParams();
        params = payload.datetime_from
            ? params.append('datetime_from', "" + payload.datetime_from)
            : params;
        params = payload.datetime_to
            ? params.append('datetime_to', "" + payload.datetime_to)
            : params;
        params = payload.rid
            ? params.append('rId', "" + payload.rid)
            : params;
        params = payload.type
            ? params.append('type', "" + payload.type)
            : params;
        params = payload.cid
            ? params.append('cid', "" + payload.cid)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this._httpClient.get('/audit', { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.prototype.getIpdetails = function (ip) {
        return this._httpClient.get("/ipdetails/" + ip).pipe(map(function (body) {
            return body;
        }));
    };
    UsersService.ngInjectableDef = i0.defineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.inject(i1.HttpClient)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
