import { EventEmitter } from '@angular/core';
var ErrorMessageService = /** @class */ (function () {
    function ErrorMessageService() {
        this._errors = [];
        this.errors$ = new EventEmitter();
    }
    Object.defineProperty(ErrorMessageService.prototype, "errors", {
        get: function () {
            return this._errors;
        },
        enumerable: true,
        configurable: true
    });
    ErrorMessageService.prototype.set = function (error, type, serviceUrl) {
        this._errors.push({
            id: Date.now(),
            error: error,
            type: type,
            serviceUrl: serviceUrl
        });
        console.log(this._errors);
        this.errors$.emit(this._errors);
    };
    ErrorMessageService.prototype.clear = function () {
        this._errors = [];
        this.errors$.emit(this._errors);
    };
    return ErrorMessageService;
}());
export { ErrorMessageService };
