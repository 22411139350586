/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./table-loader.component";
var styles_TableLoaderComponent = [i0.styles];
var RenderType_TableLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableLoaderComponent, data: {} });
export { RenderType_TableLoaderComponent as RenderType_TableLoaderComponent };
function View_TableLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card loader-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "card-body loader-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "loader d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-spinner app-loader"]], null, null, null, null, null))], null, null); }
export function View_TableLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TableLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-loader", [], null, null, null, View_TableLoaderComponent_0, RenderType_TableLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.TableLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableLoaderComponentNgFactory = i1.ɵccf("app-table-loader", i3.TableLoaderComponent, View_TableLoaderComponent_Host_0, { isLoading: "isLoading" }, {}, []);
export { TableLoaderComponentNgFactory as TableLoaderComponentNgFactory };
