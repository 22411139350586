<form
  [formGroup]="userCreateForm"
  (ngSubmit)="createUser()"
  class="form-horizontal form-material"
  id="userCreateForm"
  novalidate
>
  <div class="modal-header">
    <h4 class="modal-title">Create User</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-12 col-xl-3">First Name</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'firstName'"
          [control]="userCreateForm.controls['firstName']"
          [controlName]="'Firstname'"
        >
          <input class="form-control" type="text" formControlName="firstName" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Last Name</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'lastName'"
          [control]="userCreateForm.controls['lastName']"
          [controlName]="'Lastname'"
        >
          <input class="form-control" type="text" formControlName="lastName" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Street Address</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'address'"
          [control]="userCreateForm.controls['address']"
          [controlName]="'Street address'"
        >
          <input class="form-control" type="text" formControlName="address" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">City</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'city'"
          [control]="userCreateForm.controls['city']"
          [controlName]="'City'"
        >
          <input class="form-control" type="text" formControlName="city" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">State</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'state'"
          [control]="userCreateForm.controls['state']"
          [controlName]="'State'"
        >
          <!-- <input class="form-control" type="text" formControlName="city" /> -->
          <select class="custom-select form-control" formControlName="state">
            <option value="">Select State</option>
            <option *ngFor="let stat of states" value="{{ stat.code }}">{{
              stat.name
            }}</option>
          </select>
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Zip Code</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'zipcode'"
          [control]="userCreateForm.controls['zipcode']"
          [controlName]="'Zipcode'"
        >
          <input
            class="form-control"
            type="tel"
            mask="00000"
            formControlName="zipcode"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Phone Number</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'phone'"
          [control]="userCreateForm.controls['phone']"
          [controlName]="'Phone No.'"
        >
          <input
            class="form-control"
            type="tel"
            mask="000-000-0000"
            formControlName="phone"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Email</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'email'"
          [control]="userCreateForm.controls['email']"
          [controlName]="'Email'"
        >
          <input class="form-control" type="email" formControlName="email" />
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button
      class="btn btn-success"
      [disabled]="userCreateForm.invalid || isLoading"
    >
      <app-button-loader
        [isLoading]="isLoading"
        [label]="'Create User'"
      ></app-button-loader>
    </button>
  </div>
</form>
