<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <div>
        <a
          class="navbar-brand"
          *ngIf="loggedInUserType !== 'admin'"
          [routerLink]="['/dashboard']"
        >
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <!-- <img src="assets/images/pronto-logo.svg" alt="Pronto" class="dark-logo" style="width: 40px;" /> -->
            <!-- Light Logo icon -->
            <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" /> -->
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span style="font-weight:bold;">
            <!-- dark Logo text -->
            <!-- <img src="assets/images/pronto-name.svg" alt="Pronto" class="dark-logo" style="width: 130px;" /> -->
            <img
              src="assets/images/dowc-logo-green-bg.jpg"
              alt="DOWC"
              class="dark-logo"
              style="width: 95px;"
            />
            Producer Information Portal
            <!-- Light Logo text -->
            <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" /> -->
          </span>
        </a>
        <a
          class="navbar-brand"
          *ngIf="loggedInUserType === 'admin'"
          [routerLink]="['/agents']"
        >
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <!-- <img src="assets/images/pronto-logo.svg" alt="Pronto" class="dark-logo" style="width: 40px;" /> -->
            <!-- Light Logo icon -->
            <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" /> -->
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span style="font-weight:bold;">
            <!-- dark Logo text -->
            <!-- <img src="assets/images/pronto-name.svg" alt="Pronto" class="dark-logo" style="width: 130px;" /> -->
            <img
              src="assets/images/dowc-logo.png"
              alt="DOWC"
              class="dark-logo"
              style="width: 95px;"
            />
            Producer Information Portal
            <!-- Light Logo text -->
            <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" /> -->
          </span>
        </a>
        <!-- <a routerLink="/dashboard" *ngIf="(loggedInUserType !== 'admin' && router.url !== '/dashboard') || (loggedInUserType === 'admin' && (router.url !== '/agents' && router.url !== '/dashboard'))" class="back-icon" placement="bottom"
    ngbTooltip="Back to dashboard"><i class="mdi mdi-arrow-left"></i></a> -->
        <div
          ngbDropdown
          class="d-inline-block ml-3 navbar-nav header-agents-filter-container-bgcolor"
          *ngIf="selectedAgent != 'null' && loggedInUserType === 'admin'"
        >
          <!-- <i
              class="icon-options-vertical cursor-pointer"
              id="dropdownManual"
              ngbDropdownToggle
            ></i> -->
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownManual"
            class="customdrpdown"
          >
            <div class="form-group form-custom">
              <label
                class="d-block mb-0"
                style="border-bottom: 1px solid #eee;"
                *ngIf="loggedInUserType === 'admin'"
                >Filter By Agent</label
              >
              <select
                class="form-control custom-select mt-2"
                *ngIf="
                  selectedAgent != 'null' &&
                  router.url !== '/agents' &&
                  loggedInUserType === 'admin'
                "
                (change)="onChangeSwitchAgent($event)"
                (click)="$event.stopPropagation()"
                [value]="selectedAgent"
              >
                <option value="1">Steve Jobs</option>
                <option value="2">John Doe</option>
                <option value="3">Michel cleark</option>
              </select>
              <label
                class="agent-user-label"
                *ngIf="loggedInUserType !== 'admin'"
                >Steve Jobs
                <span *ngIf="loggedInUserType === 'agent'">(Agent)</span>
                <span *ngIf="loggedInUserType === 'agentuser'"
                  >(Agency User)</span
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-sm mobile-menu-icon menutoggler">
        <i class="mdi mdi-menu"></i>
      </button>
    </div>

    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse logotxt">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav header-menus-container mr-auto mt-md-0">
        <li class="nav-item">
          <a
            class="d-none nav-link sidebartoggler waves-effect waves-dark"
            href="javascript:void(0)"
          >
            <i class="ti-menu"></i>
          </a>
        </li>
        <!-- <li class="nav-item" *ngIf="loggedInUserType === 'agent'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="1"
            routerLink="/dashboard"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> Dashboard</a
          >
        </li> -->
        <li class="nav-item" *ngIf="loggedInUserType === 'super-admin-dowc'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="2"
            routerLink="/agents"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-human-greeting mr-2"></i> Agency</a
          >
        </li>
        <li
          class="nav-item"
          *ngIf="loggedInUserType !== 'admin' || loggedInUserType === 'admin'"
        >
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="2"
            routerLink="/dashboard"
            [ngClass]="{
              active:
                pageInfo && pageInfo.active && pageInfo.active === 'manageDeal'
            }"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-briefcase-check mr-2"></i>Deals</a
          >
        </li>
        <!-- <li class="nav-item" *ngIf="loggedInUserType === 'agent'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/profile"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-account"></i> Profile</a
          >
        </li> -->
        <!-- <li class="nav-item" *ngIf="loggedInUserType === 'admin'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/admin/profile"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-account"></i> Profile</a
          >
        </li> -->
        <li
          class="nav-item"
          *ngIf="loggedInUserType !== 'admin' || loggedInUserType === 'admin'"
        >
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/groups"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-hexagon-multiple mr-2"></i>Groups
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            loggedInUserType === 'super-admin-dowc' ||
            loggedInUserType === 'admin-dowc' ||
            loggedInUserType === 'admin' ||
            loggedInUserType === 'super-admin-agency' ||
            loggedInUserType === 'super-admin-sales'
          "
        >
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/users"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-account-multiple mr-2"></i>Users
          </a>
        </li>
        <li class="nav-item" *ngIf="loggedInUserType === 'super-admin-dowc'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/analytics"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-dashboard mr-2"></i>Analytics
          </a>
        </li>
      </ul>

      <!-- <ul class="navbar-nav breadcrumb-container mr-auto mt-md-0">
        <li class="breadcrumb-item" *ngIf="pageInfo" routerLink="{{pageInfo?.url}}">{{pageInfo?.title}}</li>
        <li class="breadcrumb-item" *ngIf="agentName.length && loggedInUserType === 'admin'">{{agentName}}</li>
        <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
          <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
            <a href='javascript:void(0)'>{{url.title}}</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
        </ng-template>
      </ul> -->

      <!-- =========================  -->
      <!-- Agent Filter -->
      <!-- =========================  -->

      <!-- <ul class="navbar-nav header-agents-filter-container  mt-md-0">
        <a routerLink="/dashboard" *ngIf="(loggedInUserType !== 'admin' && router.url !== '/dashboard') || (loggedInUserType === 'admin' && (router.url !== '/agents' && router.url !== '/dashboard'))" class="back-icon" placement="bottom"
        ngbTooltip="Back to dashboard"><i class="mdi mdi-arrow-left"></i></a>
        <select
          class="form-control custom-select"
          *ngIf="
            selectedAgent &&
            selectedAgent >= 1 &&
            router.url !== '/agents' &&
            loggedInUserType === 'admin'
          "
          (change)="onChangeSwitchAgent($event)"
          [value]="selectedAgent"
        >
          <option value="1">Steve Jobs</option>
          <option value="2">John Doe</option>
          <option value="3">Michel cleark</option>
        </select>
        <label class="agent-user-label" *ngIf="loggedInUserType !== 'admin'"
          >Steve Jobs
          <span *ngIf="loggedInUserType === 'agent'">(Agent)</span>
          <span *ngIf="loggedInUserType === 'agentuser'">(Agency User)</span>
        </label>
      </ul> -->

      <!-- =========================  -->

      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0 ml-auto">
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item hidden-sm-down search-box" > <a class="nav-link hidden-sm-down text-muted waves-effect waves-dark" href="javascript:void(0)"><i class="ti-search"></i></a>
                    <form class="app-search">
                        <input type="text" class="form-control" placeholder="Search & enter"> <a class="srh-btn"><i class="ti-close"></i></a> </form>
                </li> -->
        <!-- ============================================================== -->
        <!-- Comment -->
        <!-- ============================================================== -->
        <li
          class="nav-item dropdown d-flex flex-row-reverse"
          ngbDropdown
          placement="bottom-right"
        >
          <a
            ngbDropdownToggle
            class="d-none nav-link waves-effect waves-dark"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-message"></i>
            <div class="notify">
              <span class="heartbit"></span>
              <span class="point"></span>
            </div>
          </a>
          <div
            class="dropdown-menu mailbox animated slideInDown"
            ngbDropdownMenu
          >
            <ul>
              <li>
                <div class="drop-title">Notifications</div>
              </li>
              <li>
                <div class="message-center" [perfectScrollbar]="config">
                  <!-- Message -->
                  <a href="#" *ngFor="let notification of notifications">
                    <div class="round {{ notification.round }}">
                      <i class="{{ notification.icon }}"></i>
                    </div>
                    <div class="mail-contnet">
                      <h5>{{ notification.title }}</h5>
                      <span class="mail-desc">{{ notification.subject }}</span>
                      <span class="time">{{ notification.time }}</span>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <a class="nav-link text-center" href="javascript:void(0);">
                  <strong>Check all notifications</strong>
                  <i class="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <!-- ============================================================== -->
        <!-- End Comment -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Messages -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)"> <i class="mdi mdi-email"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                    </a>
                    <div class="dropdown-menu mailbox animated slideInDown" ngbDropdownMenu>
                        <ul>
                            <li>
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <a href="#" *ngFor="let mymessage of mymessages">
                                        <div class="user-img"> <img src="{{mymessage.useravatar}}" alt="user" class="img-circle" width="40">
                                            <span class="profile-status {{mymessage.status}} pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{mymessage.from}}</h5>
                                            <span class="mail-desc">{{mymessage.subject}}</span>
                                            <span class="time">{{mymessage.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);"> <strong>See all e-Mails</strong> <i class="fa fa-angle-right"></i> </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
        <!-- ============================================================== -->
        <!-- End Messages -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item">
          <a
            class="nav-link f-bold text-primary"
            href="javascript:void(0)"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="assets/images/users/1.jpg"
              alt="user"
              class="profile-pic mr-2"
            />
            John Doe</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link f-bold text-danger"
            href="javascript:;"
            (click)="logout()"
            title="Logout"
          >
            <i class="fa fa-power-off"></i>
          </a>
        </li> -->
        <li
          class="nav-item dropdown"
          ngbDropdown
          placement="bottom-right"
          *ngIf="user"
        >
          <a
            ngbDropdownToggle
            class="nav-link dropdown-toggle text-white  waves-effect waves-dark"
            href="javascript:void(0)"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <img
              src="assets/images/users/1.jpg"
              alt="user"
              class="profile-pic"
            /> -->
            <b *ngIf="loggedInUserType !== 'agency'" class="f-bold ml-2"
              >{{ user.firstName }} {{ user.lastName }}</b
            >
            <b *ngIf="loggedInUserType === 'agency'" class="f-bold ml-2">{{
              user.agencyname
            }}</b>
            <i class="fa fa-user-circle fa-fw"></i>
          </a>
          <div
            class="dropdown-menu animated slideInDown text-white"
            ngbDropdownMenu
          >
            <ul class="dropdown-user">
              <li>
                <div class="dw-user-box">
                  <!-- <div class="u-img">
                    <img src="assets/images/users/1.jpg" alt="user" />
                  </div> -->
                  <div class="u-text">
                    <!-- <h4 *ngIf="user">{{user.firstName}} {{user.lastName}}</h4> -->
                    <h4 *ngIf="loggedInUserType !== 'agency'">
                      {{ user.firstName }} {{ user.lastName }}
                    </h4>
                    <h4 *ngIf="loggedInUserType === 'agency'">
                      {{ user.agencyname }}
                    </h4>
                  </div>
                </div>
              </li>
              <li role="separator" class="divider"></li>
              <li
                *ngIf="
                  user.audit &&
                  user.audit == 1 &&
                  loggedInUserType === 'super-admin-dowc'
                "
              >
                <a [routerLink]="['/audit']" [routerLinkActive]="['active']"
                  ><i class="fa fa-suitcase"></i> Audit</a
                >
              </li>
              <li>
                <a [routerLink]="['/profile']" [routerLinkActive]="['active']"
                  ><i class="ti-user"></i> Profile</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/change-password']"
                  [routerLinkActive]="['active']"
                  ><i class="fa fa-key"></i> Change Password</a
                >
              </li>
              <li>
                <a href="javascript:;" (click)="logout()">
                  <i class="fa fa-power-off"></i> Logout</a
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

<aside class="mobile-menu-container">
  <nav>
    <ul class="navbar-nav siderbar-menus-container mr-auto mt-md-0">
      <li class="nav-item" *ngIf="loggedInUserType === 'super-admin-dowc'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="2"
          routerLink="/agents"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi mdi-human-greeting mr-2"></i> </span> Agency</a
        >
      </li>
      <li
        class="nav-item"
        *ngIf="
          loggedInUserType !== 'admin' ||
          (loggedInUserType === 'admin' && selectedAgent != 'null')
        "
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="2"
          routerLink="/dashboard"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi mdi-briefcase-check mr-2"></i> </span> Deals</a
        >
      </li>
      <li
        class="nav-item"
        *ngIf="loggedInUserType !== 'admin' || loggedInUserType === 'admin'"
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/groups"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi mdi-hexagon-multiple mr-2"></i> </span> Groups
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="
          loggedInUserType === 'super-admin-dowc' ||
          loggedInUserType === 'admin-dowc' ||
          loggedInUserType === 'admin' ||
          loggedInUserType === 'super-admin-agency' ||
          loggedInUserType === 'super-admin-sales'
        "
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/users"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi mdi-account-multiple mr-2"></i> </span> Users
        </a>
      </li>
      <li class="nav-item" *ngIf="loggedInUserType === 'super-admin-dowc'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/analytics"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="fa fa-dashboard mr-2"></i> </span> Analytics
        </a>
      </li>
      <hr />
      <li
        class="nav-item"
        *ngIf="
          user.audit &&
          user.audit == 1 &&
          loggedInUserType === 'super-admin-dowc'
        "
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/audit"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi fa-suitcase mr-2"></i> </span> Audit</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/profile"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi mdi-account mr-2"></i> </span> Profile</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/change-password"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <span> <i class="mdi mdi-key mr-2"></i> </span> Change Password</a
        >
      </li>      
      <li class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          (click)="logout()"
          title="Logout"
        >
          <span> <i class="fa fa-power-off mr-2"></i> </span> Logout
        </a>
      </li>
      <li class="nav-item border-top">
        <a
          class="nav-link"
          href="javascript:void(0)"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <!-- <img
            src="assets/images/users/1.jpg"
            alt="user"
            class="profile-pic mr-2"
          /> -->
          {{ user.firstName }} {{ user.lastName }}</a
        >
      </li>
    </ul>
  </nav>
</aside>

<!-- <ul class="navbar-nav header-agents-filter-container mr-auto mt-md-0"> -->
<!-- <a
    routerLink="/dashboard"
    *ngIf="
      (loggedInUserType !== 'admin' && router.url !== '/dashboard') ||
      (loggedInUserType === 'admin' &&
        (router.url !== '/agents' && router.url !== '/dashboard'))
    "
    class="back-icon"
    placement="bottom"
    ngbTooltip="Back to dashboard"
    ><i class="mdi mdi-arrow-left"></i
    ><span class="back_txt">Back to dashboard</span></a
  > -->
<!-- <select
    class="form-control custom-select"
    *ngIf="
      selectedAgent &&
      selectedAgent >= 1 &&
      router.url !== '/agents' &&
      loggedInUserType === 'admin'
    "
    (change)="onChangeSwitchAgent($event)"
    [value]="selectedAgent"
  >
    <option value="1">Steve Jobs</option>
    <option value="2">John Doe</option>
    <option value="3">Michel cleark</option>
  </select>
  <label class="agent-user-label" *ngIf="loggedInUserType !== 'admin'"
    >Steve Jobs
    <span *ngIf="loggedInUserType === 'agent'">(Agent)</span>
    <span *ngIf="loggedInUserType === 'user'">(Agency User)</span>
  </label>
</ul> -->
