<form [formGroup]="helpForm" (ngSubmit)="helpaction()">
  <div class="modal-header bg-inprogress">
    <h4 class="modal-title text-white">Help</h4>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <label class="col-12">First Name</label>
      <div class="col-12">
        <app-form-error-wrapper
          [control]="helpForm.get('firstName')"
          [controlName]="'Enter FirstName'"
          [apiErrorType]="'firstName'"
        >
          <input
            type="text"
            name="firstName"
            class="form-control"
            [readonly]="user ? true : false"
            formControlName="firstName"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-12">Last Name</label>
      <div class="col-12">
        <app-form-error-wrapper
          [control]="helpForm.get('lastName')"
          [controlName]="'Enter LastName'"
          [apiErrorType]="'lastName'"
        >
          <input
            type="text"
            name="lastName"
            class="form-control"
            [readonly]="user ? true : false"
            formControlName="lastName"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-12">Email</label>
      <div class="col-12">
        <app-form-error-wrapper
          [control]="helpForm.get('email')"
          [controlName]="'Enter Email'"
          [apiErrorType]="'email'"
        >
          <input
            type="text"
            name="email"
            class="form-control"
            [readonly]="user ? true : false"
            formControlName="email"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-12">Message</label>
      <div class="col-12">
        <app-form-error-wrapper
          class="col-3 w-30"
          [control]="helpForm.get('message')"
          [controlName]="'Enter Message'"
          [apiErrorType]="'message'"
        >
          <textarea
            type="text"
            name="message"
            rows="5"
            class="form-control"
            formControlName="message"
          ></textarea>
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button class="btn btn-primary" [disabled]="helpForm.invalid" type="submit">
      <app-button-loader [label]="'Help'"></app-button-loader>
    </button>
  </div>
</form>
