<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Change Password
      </div>
      <div class="card-body">
        <form
          [formGroup]="changePasswordForm"
          (ngSubmit)="changePassword()"
          autocomplete="off"
          class="form-horizontal form-material"
          id="chnagePasswordForm"
          novalidate
        >
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication
                    [control]="changePasswordForm.controls['currentpassword']"
                    [label]="'Current Password'"
                  ></app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <app-form-error-wrapper
                    [control]="changePasswordForm.controls['currentpassword']"
                    [apiErrorType]="'oPassword'"
                    [controlName]="'CurrentPassword'"
                  >
                    <input
                      class="form-control"
                      type="password"
                      formControlName="currentpassword"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication
                    [control]="changePasswordForm.controls['newpassword']"
                    [label]="'New Password'"
                  ></app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <app-form-error-wrapper
                    [control]="changePasswordForm.controls['newpassword']"
                    [apiErrorType]="'password'"
                    [controlName]="'newpassword'"
                  >
                    <input
                      class="form-control"
                      type="password"
                      formControlName="newpassword"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication
                    [control]="changePasswordForm.controls['confirmpassword']"
                    [label]="'Confirm Password'"
                  ></app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <app-form-error-wrapper
                    [control]="changePasswordForm.controls['confirmpassword']"
                    [apiErrorType]="'cPassword'"
                    [controlName]="'confirmpassword'"
                  >
                    <input
                      class="form-control"
                      type="password"
                      formControlName="confirmpassword"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                  <span
                    class="error help-block text-danger f-14 f-500 mt-1 d-block"
                    *ngIf="
                      changePasswordForm.get('confirmpassword').value &&
                      changePasswordForm.get('newpassword').value !==
                        changePasswordForm.get('confirmpassword').value
                    "
                    >The passwords you entered do not match. Please make sure your passwords match and try again.</span
                  >
                </div>
              </div>

              <div class="form-group d-lg-flex align-items-center">
                <label class="col-12 col-lg-2"></label>
                <div class="col-12 col-lg-10">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="changePasswordForm.invalid || isLoading"
                  >
                    <app-button-loader
                      [isLoading]="isLoading"
                      [label]="'Change Password'"
                    ></app-button-loader>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
