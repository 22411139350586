import { LOCAL_VAR } from './../../../core/constants';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { STATES } from '@app/core/constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GroupsService } from '@app/core/service/groups.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  groupCreateForm: FormGroup;
  isLoading = false;
  queryParams: any;
  states: any = STATES;
  selectedAgent: any = null;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private groupsService: GroupsService
  ) {
    this.selectedAgent = localStorage.getItem(LOCAL_VAR.selectedAgent);
    this.selectedAgent = JSON.parse(this.selectedAgent);
    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
      this.createForm();
    });
  }

  ngOnInit() {}

  private createForm() {
    this.groupCreateForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(5)
        ])
      ],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      einNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(15)
        ])
      ]
    });
  }

  createGroup() {
    this.isLoading = true;
    this.groupsService
      .createAgentGroup(this.selectedAgent._id, this.groupCreateForm.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.activeModal.close(response);
      });
  }
}
