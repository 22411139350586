<h4 class="title-bg p-2">Overall Report</h4>
<div class="row">
  <div class="col-12">
    <div class="card-group">
      <!-- New -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="m-b-0">
                <i class="mdi mdi-buffer  text-new"></i>
              </h1>
              <h3 class="">{{ this.overAllDealData.alldealcount }}</h3>
              <h6 class="card-subtitle">Total Deals</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- In progress -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="m-b-0">
                <i class="mdi mdi-wallet text-inprogress"></i>
                <div class="float-right">
                  {{
                    overAllDealData.inprogress /
                      (this.overAllDealData.alldealcount * 0.01)
                      | number: '1.0-0'
                  }}%
                </div>
              </h1>
              <h3 class="">{{ overAllDealData.inprogress }}</h3>
              <h6 class="card-subtitle">In Progress</h6>
            </div>
            <div class="col-12">
              <ngb-progressbar
                [showValue]="false"
                type="inprogress"
                [value]="
                  overAllDealData.inprogress /
                  (this.overAllDealData.alldealcount * 0.01)
                "
              >
              </ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
      <!-- In review -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="m-b-0">
                <i class="mdi mdi-alert-circle text-inreview"></i>
                <div class="float-right">
                  {{
                    overAllDealData.inreview /
                      (this.overAllDealData.alldealcount * 0.01)
                      | number: '1.0-0'
                  }}%
                </div>
              </h1>
              <h3 class="">{{ overAllDealData.inreview }}</h3>
              <h6 class="card-subtitle">In Review</h6>
            </div>
            <div class="col-12">
              <ngb-progressbar
                [showValue]="false"
                type="inreview"
                [value]="
                  overAllDealData.inreview /
                  (this.overAllDealData.alldealcount * 0.01)
                "
              >
              </ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
      <!-- Rejeted -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="m-b-0">
                <i class="mdi mdi-thumb-down text-rejected"></i>
                <div class="float-right">
                  {{
                    overAllDealData.rejected /
                      (this.overAllDealData.alldealcount * 0.01)
                      | number: '1.0-0'
                  }}%
                </div>
              </h1>
              <h3 class="">{{ overAllDealData.rejected }}</h3>
              <h6 class="card-subtitle">Rejected</h6>
            </div>
            <div class="col-12">
              <ngb-progressbar
                [showValue]="false"
                type="rejected"
                [value]="
                  overAllDealData.rejected /
                  (this.overAllDealData.alldealcount * 0.01)
                "
              >
              </ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
      <!-- Approved -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h1 class="m-b-0">
                <i class="mdi mdi-thumb-up text-approved"></i>
                <div class="float-right">
                  {{
                    overAllDealData.approved /
                      (this.overAllDealData.alldealcount * 0.01)
                      | number: '1.0-0'
                  }}%
                </div>
              </h1>
              <h3 class="">{{ overAllDealData.approved }}</h3>
              <h6 class="card-subtitle">Approved</h6>
            </div>
            <div class="col-12">
              <ngb-progressbar
                [showValue]="false"
                type="approved"
                [value]="
                  overAllDealData.approved /
                  (this.overAllDealData.alldealcount * 0.01)
                "
              >
              </ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Deal Source</h4>
        <div style="min-height:410px;">
          <canvas
            baseChart
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [colors]="[
              {
                backgroundColor: [
                  '#00A5B4',
                  '#009B4C',
                  '#6A6187',
                  '#A4BB39',
                  '#DF7F58'
                ]
              }
            ]"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="row">
      <div class="col-12">
        <h4 class="title-bg p-2">DOWC</h4>
        <div class="card-group">
          <!-- New -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-buffer  text-new"></i>
                                    </h1> -->
                  <h3 class="">{{ DOWCDealData.alldealcount }}</h3>
                  <h6 class="card-subtitle">Total Deals</h6>
                </div>
              </div>
            </div>
          </div>
          <!-- In progress -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-wallet text-inprogress"></i>
                                        <div class="float-right">
                                            {{DOWCDealData.inprogress/(DOWCDealData.alldealcount*.01) |
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ DOWCDealData.inprogress }}</h3>
                  <h6 class="card-subtitle">In Progress</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inprogress"
                    [value]="
                      DOWCDealData.inprogress /
                      (DOWCDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- In review -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-alert-circle text-inreview"></i>
                                        <div class="float-right">{{DOWCDealData.inreview/(DOWCDealData.alldealcount*.01)
                                            |
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ DOWCDealData.inreview }}</h3>
                  <h6 class="card-subtitle">In Review</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inreview"
                    [value]="
                      DOWCDealData.inreview / (DOWCDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Rejeted -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-down text-rejected"></i>
                                        <div class="float-right">
                                            {{DOWCDealData.rejected/(DOWCDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ DOWCDealData.rejected }}</h3>
                  <h6 class="card-subtitle">Rejected</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="rejected"
                    [value]="
                      DOWCDealData.rejected / (DOWCDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Approved -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-up text-approved"></i>
                                        <div class="float-right">
                                            {{DOWCDealData.approved/(DOWCDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ DOWCDealData.approved }}</h3>
                  <h6 class="card-subtitle">Approved</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="approved"
                    [value]="
                      DOWCDealData.approved / (DOWCDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <h4 class="title-bg p-2">Servicecontract</h4>
        <div class="card-group">
          <!-- New -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-buffer  text-new"></i>
                                    </h1> -->
                  <h3 class="">{{ SCCOMDealData.alldealcount }}</h3>
                  <h6 class="card-subtitle">Total Deals</h6>
                </div>
              </div>
            </div>
          </div>
          <!-- In progress -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-wallet text-inprogress"></i>
                                        <div class="float-right">
                                            {{SCCOMDealData.inprogress/(SCCOMDealData.alldealcount*.01) |
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ SCCOMDealData.inprogress }}</h3>
                  <h6 class="card-subtitle">In Progress</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inprogress"
                    [value]="
                      SCCOMDealData.inprogress /
                      (SCCOMDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- In review -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-alert-circle text-inreview"></i>
                                        <div class="float-right">
                                            {{SCCOMDealData.inreview/(SCCOMDealData.alldealcount*.01) |
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ SCCOMDealData.inreview }}</h3>
                  <h6 class="card-subtitle">In Review</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inreview"
                    [value]="
                      SCCOMDealData.inreview /
                      (SCCOMDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Rejeted -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-down text-rejected"></i>
                                        <div class="float-right">
                                            {{SCCOMDealData.rejected/(SCCOMDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ SCCOMDealData.rejected }}</h3>
                  <h6 class="card-subtitle">Rejected</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="rejected"
                    [value]="
                      SCCOMDealData.rejected /
                      (SCCOMDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Approved -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-up text-approved"></i>
                                        <div class="float-right">
                                            {{SCCOMDealData.approved/(SCCOMDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ SCCOMDealData.approved }}</h3>
                  <h6 class="card-subtitle">Approved</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="approved"
                    [value]="
                      SCCOMDealData.approved /
                      (SCCOMDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <h4 class="title-bg p-2">DOWC & Servicecontract</h4>
        <div class="card-group">
          <!-- New -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-buffer  text-new"></i>
                                    </h1> -->
                  <h3 class="">{{ ScMergeDealData.alldealcount }}</h3>
                  <h6 class="card-subtitle">Total Deals</h6>
                </div>
              </div>
            </div>
          </div>
          <!-- In progress -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-wallet text-inprogress"></i>
                                        <div class="float-right">
                                            {{ScMergeDealData.inprogress/(ScMergeDealData.alldealcount*.01)
                                            | number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ ScMergeDealData.inprogress }}</h3>
                  <h6 class="card-subtitle">In Progress</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inprogress"
                    [value]="
                      ScMergeDealData.inprogress /
                      (ScMergeDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- In review -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-alert-circle text-inreview"></i>
                                        <div class="float-right">
                                            {{ScMergeDealData.inreview/(ScMergeDealData.alldealcount*.01) |
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ ScMergeDealData.inreview }}</h3>
                  <h6 class="card-subtitle">In Review</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inreview"
                    [value]="
                      ScMergeDealData.inreview /
                      (ScMergeDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Rejeted -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-down text-rejected"></i>
                                        <div class="float-right">
                                            {{ScMergeDealData.rejected/(ScMergeDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ ScMergeDealData.rejected }}</h3>
                  <h6 class="card-subtitle">Rejected</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="rejected"
                    [value]="
                      ScMergeDealData.rejected /
                      (ScMergeDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Approved -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-up text-approved"></i>
                                        <div class="float-right">
                                            {{ScMergeDealData.approved/(ScMergeDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ ScMergeDealData.approved }}</h3>
                  <h6 class="card-subtitle">Approved</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="approved"
                    [value]="
                      ScMergeDealData.approved /
                      (ScMergeDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <h4 class="title-bg p-2">Keyfetch</h4>
        <div class="card-group">
          <!-- New -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-buffer  text-new"></i>
                                    </h1> -->
                  <h3 class="">{{ keyfetchDealData.alldealcount }}</h3>
                  <h6 class="card-subtitle">Total Deals</h6>
                </div>
              </div>
            </div>
          </div>
          <!-- In progress -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-wallet text-inprogress"></i>
                                        <div class="float-right">
                                            {{keyfetchDealData.inprogress/(keyfetchDealData.alldealcount*.01) |
                                            number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ keyfetchDealData.inprogress }}</h3>
                  <h6 class="card-subtitle">In Progress</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inprogress"
                    [value]="
                      keyfetchDealData.inprogress /
                      (keyfetchDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- In review -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-alert-circle text-inreview"></i>
                                        <div class="float-right">
                                            {{keyfetchDealData.inreview/(keyfetchDealData.alldealcount*.01)
                                            | number:'1.0-0'}}%</div>
                                    </h1> -->
                  <h3 class="">{{ keyfetchDealData.inreview }}</h3>
                  <h6 class="card-subtitle">In Review</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="inreview"
                    [value]="
                      keyfetchDealData.inreview /
                      (keyfetchDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Rejeted -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-down text-rejected"></i>
                                        <div class="float-right">
                                            {{keyfetchDealData.rejected/(keyfetchDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%
                                        </div>
                                    </h1> -->
                  <h3 class="">{{ keyfetchDealData.rejected }}</h3>
                  <h6 class="card-subtitle">Rejected</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="rejected"
                    [value]="
                      keyfetchDealData.rejected /
                      (keyfetchDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
          <!-- Approved -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- <h1 class="m-b-0">
                                        <i class="mdi mdi-thumb-up text-approved"></i>
                                        <div class="float-right">
                                            {{keyfetchDealData.approved/(keyfetchDealData.alldealcount*.01)|
                                            number:'1.0-0'}}%
                                        </div>
                                    </h1> -->
                  <h3 class="">{{ keyfetchDealData.approved }}</h3>
                  <h6 class="card-subtitle">Approved</h6>
                </div>
                <div class="col-12">
                  <ngb-progressbar
                    [showValue]="false"
                    type="approved"
                    [value]="
                      keyfetchDealData.approved /
                      (keyfetchDealData.alldealcount * 0.01)
                    "
                  >
                  </ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Agencies</h4>
        <div class="table-responsive">
          <table class="table table-hover earning-box">
            <thead>
              <tr>
                <th>Agency Name</th>
                <th>Total Deals</th>
                <th>In Progress</th>
                <th>In Review</th>
                <th>Rejected</th>
                <th>Approved</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let agencydata of AgencyDealDataList">
                <td>
                  <h6>{{ agencydata.name }}</h6>
                </td>
                <td>{{ agencydata.count }}</td>
                <td>{{ agencydata.inprogress }}</td>
                <td>{{ agencydata.inreview }}</td>
                <td>{{ agencydata.rejected }}</td>
                <td>{{ agencydata.approved }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Users</h4>
        <div class="table-responsive">
          <table class="table table-hover earning-box">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Total Deals</th>
                <th>In Progress</th>
                <th>In Review</th>
                <th>Rejected</th>
                <th>Approved</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let userdata of UserDealDataList">
                <td>
                  <h6>{{ userdata.name }}</h6>
                </td>
                <td>{{ userdata.count }}</td>
                <td>{{ userdata.inprogress }}</td>
                <td>{{ userdata.inreview }}</td>
                <td>{{ userdata.rejected }}</td>
                <td>{{ userdata.approved }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <app-table-loader [isLoading]="isLoading"></app-table-loader>
</div>
