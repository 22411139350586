var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
var NgbDateCustomParserFormatter = /** @class */ (function (_super) {
    __extends(NgbDateCustomParserFormatter, _super);
    function NgbDateCustomParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateCustomParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('.');
            if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
                return { day: this.toInteger(dateParts[0]), month: null, year: null };
            }
            else if (dateParts.length === 2 &&
                this.isNumber(dateParts[0]) &&
                this.isNumber(dateParts[1])) {
                return {
                    day: this.toInteger(dateParts[0]),
                    month: this.toInteger(dateParts[1]),
                    year: null
                };
            }
            else if (dateParts.length === 3 &&
                this.isNumber(dateParts[0]) &&
                this.isNumber(dateParts[1]) &&
                this.isNumber(dateParts[2])) {
                return {
                    day: this.toInteger(dateParts[0]),
                    month: this.toInteger(dateParts[1]),
                    year: this.toInteger(dateParts[2])
                };
            }
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.padNumber = function (value) {
        if (this.isNumber(value)) {
            return ("0" + value).slice(-2);
        }
        else {
            return '';
        }
    };
    NgbDateCustomParserFormatter.prototype.isNumber = function (value) {
        return !isNaN(this.toInteger(value));
    };
    NgbDateCustomParserFormatter.prototype.toInteger = function (value) {
        return parseInt("" + value, 10);
    };
    NgbDateCustomParserFormatter.prototype.format = function (date) {
        // return date
        //   ? `${this.isNumber(date.day) ? this.padNumber(date.day) : ''}.${
        //       this.isNumber(date.month) ? this.padNumber(date.month) : ''
        //     }.${date.year}`
        //   : '';
        return date
            ? (this.isNumber(date.month) ? this.padNumber(date.month) : '') + "/" + (this.isNumber(date.day) ? this.padNumber(date.day) : '') + "/" + date.year
            : '';
    };
    return NgbDateCustomParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatter };
