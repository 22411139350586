import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-old-product-preview',
  templateUrl: './old-product-preview.component.html',
  styleUrls: ['./old-product-preview.component.scss']
})
export class OldProductPreviewComponent implements OnInit {
  // oldProducts: any[] = [];
  @Input()
  oldProducts?= '';

  constructor(
    public activeModal: NgbActiveModal
  ) { 
    console.log(this.oldProducts,'dddddddddddd')
  }
  test(){
    console.log(this.oldProducts,'testtttttt')

  }

  ngOnInit() {
  }

}
