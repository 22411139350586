import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-required-indication',
  templateUrl: './required-indication.component.html',
  styleUrls: ['./required-indication.component.scss']
})
export class RequireIndicationComponent implements OnInit {
  @Input()
  public control: any;
  @Input()
  label: string;
  constructor() {}

  ngOnInit() {}
}
