<form
  [formGroup]="groupCreateForm"
  (ngSubmit)="createGroup()"
  class="form-horizontal form-material"
  id="groupCreateForm"
  novalidate
>
  <div class="modal-header">
    <h4 class="modal-title">Create Group</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-12 col-xl-3">Group Name</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'name'"
          [control]="groupCreateForm.controls['name']"
          [controlName]="'Group name'"
        >
          <input class="form-control" type="text" formControlName="name" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Street Address</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'address'"
          [control]="groupCreateForm.controls['address']"
          [controlName]="'Street Address'"
        >
          <input class="form-control" type="text" formControlName="address" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">City</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'city'"
          [control]="groupCreateForm.controls['city']"
          [controlName]="'City'"
        >
          <input class="form-control" type="text" formControlName="city" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">State</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'state'"
          [control]="groupCreateForm.controls['state']"
          [controlName]="'State'"
        >
          <select class="custom-select form-control" formControlName="state">
            <option value="">Select State</option>
            <option *ngFor="let stat of states" value="{{ stat.code }}">{{
              stat.name
            }}</option>
          </select>
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Zip Code</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'zip'"
          [control]="groupCreateForm.controls['zip']"
          [controlName]="'Zip Code'"
        >
          <input
            class="form-control"
            type="tel"
            mask="00000"
            formControlName="zip"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Phone Number</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'phone'"
          [control]="groupCreateForm.controls['phone']"
          [controlName]="'Phone Number'"
        >
          <input
            class="form-control"
            type="tel"
            mask="000-000-0000"
            formControlName="phone"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Email</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'email'"
          [control]="groupCreateForm.controls['email']"
          [controlName]="'Email'"
        >
          <input class="form-control" type="email" formControlName="email" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">EIN Number</label>
      <div class="col-12 col-xl-9">
        <app-form-error-wrapper
          [apiErrorType]="'einNumber'"
          [control]="groupCreateForm.controls['einNumber']"
          [controlName]="'EIN Number'"
        >
          <input
            class="form-control"
            type="text"
            mask="000-000-0000"
            formControlName="einNumber"
          />
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button
      class="btn btn-success"
      [disabled]="groupCreateForm.invalid || isLoading"
    >
      <app-button-loader
        [isLoading]="isLoading"
        [label]="'Create Group'"
      ></app-button-loader>
    </button>
  </div>
</form>
