import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DealsService } from '@app/core/service/deals.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-deal-help',
  templateUrl: './deal-help.component.html',
  styleUrls: ['./deal-help.component.scss']
})
export class DealHelpComponent implements OnInit {
  helpForm: FormGroup;
  helpFormdata: any;
  user: Authentication.User;

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public dealService: DealsService,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.credentials
      ? this.authenticationService.credentials.user
      : null;
    this.createForm();
    //console.log(this.user);
  }

  ngOnInit() {}
  createForm() {
    this.helpForm = this.formBuilder.group({
      firstName: [this.user ? this.user.firstName : '', [Validators.required]],
      lastName: [this.user ? this.user.lastName : '', [Validators.required]],
      email: [
        this.user ? this.user.email : '',
        [
          Validators.required,
          Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
        ]
      ],
      message: ['', [Validators.required]]
    });
  }
  helpaction() {
    this.helpFormdata = this.helpForm.value;
    if (this.helpForm.valid) {
      this.dealService
        .helprequest(this.helpFormdata)
        .subscribe((response: any) => {
          console.log(response, 'Accept Request response');
          if (response) {
            this.toastrService.info('Request raised Successfully..!');
            this.activeModal.dismiss(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    }
  }
}
