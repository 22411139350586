<form [formGroup]="rejectForm" (ngSubmit)="rejectRequest()">
  <div class="modal-header">
    <h4 class="modal-title">Reject Request</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <!-- <p class="col-12 mb-0">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      </p> -->
    </div>
    <div class="form-group row">
      <label class="col-12">What is reason behind reject request ?</label>
      <div class="col-12">
        <app-form-error-wrapper
          class="col-3 w-30"
          [control]="rejectForm.get('message')"
          [controlName]="'Enter Message'"
        >
          <textarea
            type="text"
            name="reason"
            rows="5"
            class="form-control"
            formControlName="message"
          ></textarea>
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button
      [disabled]="rejectForm.invalid"
      class="btn btn-danger bg-rejected"
      type="submit"
    >
      <app-button-loader [label]="'Reject Request'"></app-button-loader>
    </button>
  </div>
</form>
