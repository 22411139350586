import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UsersService } from '@app/core/service/users.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-ip-detail',
  templateUrl: './ip-detail.component.html',
  styleUrls: ['./ip-detail.component.scss']
})
export class IpDetailComponent implements OnInit {
  @Input()
  ip?= '';
  ipDetails?= {};
  country?= '';
  countryCode?= '';
  region?= '';
  regionName?= '';
  city?= '';
  message?= '';
  ipFormdata: any;
  user: Authentication.User;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private UsersService: UsersService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.createForm();
  }
  ngOnInit() {


  }
  createForm() {


  }
}
