import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { ErrorMessageService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  pageInfo: any;
  liveMode: boolean;
  windowScrolled: boolean;
  topOffset: number = 150;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private errorMessageService: ErrorMessageService
  ) {
    // REF for unsubscribe: https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.errorMessageService.clear();

        const prefix = '';
        // if (!this.liveMode && event && event.testLabel === true) {
        //   prefix = '';
        // }

        this.titleService.setTitle(
          `${prefix}${
            event['title'] ? event['title'] : 'Producer Information Portal'
          }`
        );
        setTimeout(() => {
          this.titleService.setTitle(
            `${prefix}${
              event['title'] ? event['title'] : 'Producer Information Portal'
            }`
          );
        }, 500);
        this.pageInfo = event;
      });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      window.pageYOffset > this.topOffset ||
      document.documentElement.scrollTop > this.topOffset ||
      document.body.scrollTop > this.topOffset
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }


  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }
}
