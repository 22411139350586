import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ChangePasswordService = /** @class */ (function () {
    function ChangePasswordService(httpClient) {
        this.httpClient = httpClient;
    }
    ChangePasswordService.prototype.updatePassword = function (payload) {
        return this.httpClient.put('/updatepassword', payload).pipe(map(function (body) {
            return body;
        }));
    };
    ChangePasswordService.ngInjectableDef = i0.defineInjectable({ factory: function ChangePasswordService_Factory() { return new ChangePasswordService(i0.inject(i1.HttpClient)); }, token: ChangePasswordService, providedIn: "root" });
    return ChangePasswordService;
}());
export { ChangePasswordService };
