import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  constructor(private httpClient: HttpClient) {}

  updatePassword(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.put('/updatepassword', payload).pipe(
      map((body: Authentication.Credentials) => {
        return body;
      })
    );
  }
}
