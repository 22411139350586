/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./blank.component";
var styles_BlankComponent = [];
var RenderType_BlankComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BlankComponent, data: {} });
export { RenderType_BlankComponent as RenderType_BlankComponent };
export function View_BlankComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BlankComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-blank-layout", [], null, null, null, View_BlankComponent_0, RenderType_BlankComponent)), i0.ɵdid(1, 49152, null, 0, i2.BlankComponent, [], null, null)], null, null); }
var BlankComponentNgFactory = i0.ɵccf("app-blank-layout", i2.BlankComponent, View_BlankComponent_Host_0, {}, {}, []);
export { BlankComponentNgFactory as BlankComponentNgFactory };
