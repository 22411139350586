import { Injectable } from '@angular/core';

import * as Constants from './constants.lib';
import { ReTree } from './reTree.lib';

export interface DeviceInfo {
  userAgent: string;
  os: string;
  browser: string;
  device: string;
  os_version: string;
  browser_version: string;
}

@Injectable()
export class UAParserService {
  getDeviceInfo(userAgent: any) {
    const constants: any = Constants;
    const obj: any = {
      ua: '',
      userAgent: '',
      os: '',
      browser: '',
      device: '',
      os_version: '',
      browser_version: ''
    };
    const reTree: any = new ReTree();
    const ua: any = userAgent;
    userAgent = ua;
    const mappings: any[] = [
      { const: 'OS', prop: 'os' },
      {
        const: 'BROWSERS',
        prop: 'browser'
      },
      {
        const: 'DEVICES',
        prop: 'device'
      },
      {
        const: 'OS_VERSIONS',
        prop: 'os_version'
      }
    ];

    mappings.forEach((mapping: any) => {
      obj[mapping.prop] = Object.keys(constants[mapping.const]).reduce(
        (key: any, item: any) => {
          key[constants[mapping.const][item]] = reTree.test(
            ua,
            constants[`${mapping.const}_RE`][item]
          );
          return key;
        },
        {}
      );
    });

    mappings.forEach((mapping: any) => {
      obj[mapping.prop] = Object.keys(constants[mapping.const])
        .map(key => {
          return constants[mapping.const][key];
        })
        .reduce((previousValue, currentValue) => {
          return previousValue === constants[mapping.const].UNKNOWN &&
            obj[mapping.prop][currentValue]
            ? currentValue
            : previousValue;
        }, constants[mapping.const].UNKNOWN);
    });

    obj.browser_version = '0';
    if (obj.browser !== Constants.BROWSERS.UNKNOWN) {
      const re = Constants.BROWSER_VERSIONS_RE[obj.browser];
      const res = reTree.exec(ua, re);
      if (!!res) {
        obj.browser_version = res[1];
      }
    }

    const deviceInfo: DeviceInfo = {
      userAgent: obj.userAgent,
      os: obj.os,
      browser: obj.browser,
      device: obj.device,
      os_version: obj.os_version,
      browser_version: obj.browser_version
    };
    return deviceInfo;
  }
}
