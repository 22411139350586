var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseAPIClass } from '../class/baseAPI.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GroupsService = /** @class */ (function (_super) {
    __extends(GroupsService, _super);
    function GroupsService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        return _this;
    }
    GroupsService.prototype.getAgentGroups = function (agentId) {
        return this.httpClient.get("/agent/" + agentId + "/groups").pipe(map(function (body) {
            return body;
        }));
    };
    GroupsService.prototype.getFilterAgentGroups = function (agentId, payload) {
        return this.httpClient.post("/agent/" + agentId + "/filtergroups", payload).pipe(map(function (body) {
            return body;
        }));
    };
    GroupsService.prototype.getAgentGroupDetail = function (agentId, groupId) {
        return this.httpClient.get("/agent/" + agentId + "/group/" + groupId).pipe(map(function (body) {
            return body;
        }));
    };
    GroupsService.prototype.createAgentGroup = function (agentId, payload) {
        return this.httpClient.post("/agent/" + agentId + "/group", payload).pipe(map(function (body) {
            return body;
        }));
    };
    GroupsService.prototype.updateAgentGroup = function (agentId, groupId, payload) {
        return this.httpClient
            .put("/agent/" + agentId + "/group/" + groupId, payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    GroupsService.prototype.deleteAgentGroup = function (agentId, groupId) {
        return this.httpClient.delete("/agent/" + agentId + "/group/" + groupId).pipe(map(function (body) {
            return body;
        }));
    };
    GroupsService.ngInjectableDef = i0.defineInjectable({ factory: function GroupsService_Factory() { return new GroupsService(i0.inject(i1.HttpClient)); }, token: GroupsService, providedIn: "root" });
    return GroupsService;
}(BaseAPIClass));
export { GroupsService };
