/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../button-loader/button-loader.component.ngfactory";
import * as i3 from "../../button-loader/button-loader.component";
import * as i4 from "../../pipes/safe-html.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./confirmation.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "@angular/router";
var styles_ConfirmationComponent = [i0.styles];
var RenderType_ConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationComponent, data: {} });
export { RenderType_ConfirmationComponent as RenderType_ConfirmationComponent };
function View_ConfirmationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.cancelBtn; _ck(_v, 1, 0, currVal_1); }); }
function View_ConfirmationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSuccess() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-button-loader", [], null, null, null, i2.View_ButtonLoaderComponent_0, i2.RenderType_ButtonLoaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.ButtonLoaderComponent, [], { isLoading: [0, "isLoading"], label: [1, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; var currVal_2 = _co.okBtn; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 0, 0, currVal_0); }); }
export function View_ConfirmationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "modal-title"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationComponent_1)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationComponent_2)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.cancelBtn && _co.cancelBtn.length); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.okBtn && _co.okBtn.length); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.body)); _ck(_v, 5, 0, currVal_1); }); }
export function View_ConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation", [], null, null, null, View_ConfirmationComponent_0, RenderType_ConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmationComponent, [i8.NgbActiveModal, i1.Injector, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationComponentNgFactory = i1.ɵccf("app-confirmation", i7.ConfirmationComponent, View_ConfirmationComponent_Host_0, { title: "title", body: "body", okBtn: "okBtn", cancelBtn: "cancelBtn", service: "service", method: "method", payload: "payload" }, {}, []);
export { ConfirmationComponentNgFactory as ConfirmationComponentNgFactory };
