import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AgentsService = /** @class */ (function () {
    function AgentsService(httpClient) {
        this.httpClient = httpClient;
    }
    //todo - remove later
    AgentsService.prototype.getAgents = function () {
        return this.httpClient.get("/agents").pipe(map(function (body) {
            return body;
        }));
    };
    //todo - remove later
    AgentsService.prototype.getAgentDetail = function (agentId) {
        return this.httpClient.get("/agent/" + agentId).pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.getAgencyDetail = function (agencyId) {
        return this.httpClient.get("/agent/" + agencyId).pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.createAgency = function (payload) {
        return this.httpClient.post("/agency", payload).pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.getAgencyList = function () {
        return this.httpClient.get("/getagencylist").pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.getSubAgencies = function (agentId) {
        return this.httpClient.get("/agent/" + agentId + "/subagency").pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.getAgencyUser = function () {
        return this.httpClient.get("/getagencyusers").pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.updateAgent = function (agentId, payload) {
        return this.httpClient.put("/agent/" + agentId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.deleteAgent = function (agentId) {
        return this.httpClient.delete("/agent/" + agentId).pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.prototype.getAgencyFilterList = function (payload) {
        return this.httpClient.post("/getagencyfilterlist", payload).pipe(map(function (body) {
            return body;
        }));
    };
    AgentsService.ngInjectableDef = i0.defineInjectable({ factory: function AgentsService_Factory() { return new AgentsService(i0.inject(i1.HttpClient)); }, token: AgentsService, providedIn: "root" });
    return AgentsService;
}());
export { AgentsService };
