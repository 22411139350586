import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss']
})
export class TableLoaderComponent implements OnInit {
  @Input()
  isLoading = false;

  constructor() {}

  ngOnInit() {}
}
