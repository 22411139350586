import { CurrencyMaskConfig } from 'ngx-currency/src/currency-mask.config';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
export var CustomCurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '$ ',
    suffix: '',
    thousands: ',',
    nullable: false
};
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
