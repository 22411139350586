/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./required-indication.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./required-indication.component";
var styles_RequireIndicationComponent = [i0.styles];
var RenderType_RequireIndicationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequireIndicationComponent, data: {} });
export { RenderType_RequireIndicationComponent as RenderType_RequireIndicationComponent };
function View_RequireIndicationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sup", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*"]))], null, null); }
export function View_RequireIndicationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequireIndicationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.control && _co.control.validator) && _co.control.validator("")) && _co.control.validator("").required); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 0, 0, currVal_0); }); }
export function View_RequireIndicationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-required-indication", [], null, null, null, View_RequireIndicationComponent_0, RenderType_RequireIndicationComponent)), i1.ɵdid(1, 114688, null, 0, i3.RequireIndicationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequireIndicationComponentNgFactory = i1.ɵccf("app-required-indication", i3.RequireIndicationComponent, View_RequireIndicationComponent_Host_0, { control: "control", label: "label" }, {}, []);
export { RequireIndicationComponentNgFactory as RequireIndicationComponentNgFactory };
