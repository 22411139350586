import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export abstract class BaseAPIClass {
  baseUrl: string;
  constructor(protected httpClient: HttpClient) {}

  getAll(filterObject?: any, appendUrl?: string): Observable<any> {
    let finalUrl = this.baseUrl;
    if (appendUrl) {
      finalUrl = `${this.baseUrl}/${appendUrl}`;
    }
    let queryString = '';
    if (filterObject) {
      const fitlerKeys: any[] = Object.keys(filterObject);
      if (fitlerKeys.length > 0) {
        queryString = '?';
      }
      fitlerKeys.forEach((key: any, index) => {
        if (filterObject[key] !== undefined && filterObject[key] !== null) {
          if (typeof filterObject[key] === 'object') {
            filterObject[key].forEach((value: any) => {
              queryString += `${key}=${value}&`;
            });
          } else {
            if (filterObject[key].toString().length) {
              queryString += `${key}=${filterObject[key]}&`;
            }
          }
        }
      });
      if (
        fitlerKeys.length > 0 &&
        queryString[queryString.length - 1] === '&'
      ) {
        queryString = queryString.slice(0, -1);
      }
    }
    return this.httpClient.get(`${finalUrl}${queryString}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getById(id: string, filterObject?: any, appendUrl?: string): Observable<any> {
    let queryString = '';
    if (filterObject) {
      const fitlerKeys: any[] = Object.keys(filterObject);
      if (fitlerKeys.length > 0) {
        queryString = '?';
      }
      fitlerKeys.forEach((key: any, index) => {
        if (filterObject[key] !== undefined && filterObject[key] !== null) {
          if (filterObject[key].toString().length) {
            queryString += `${key}=${filterObject[key]}&`;
          }
        }
      });
      if (
        fitlerKeys.length > 0 &&
        queryString[queryString.length - 1] === '&'
      ) {
        queryString = queryString.slice(0, -1);
      }
    }
    let finalUrl = `${this.baseUrl}/${id}`;
    if (appendUrl) {
      finalUrl = `${finalUrl}/${appendUrl}`;
    }
    return this.httpClient.get(`${finalUrl}${queryString}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  create(payload: any, appendUrl?: string): Observable<any> {
    let finalUrl = this.baseUrl;
    if (appendUrl) {
      finalUrl = `${this.baseUrl}/${appendUrl}`;
    }
    return this.httpClient.post(finalUrl, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  update(id: string, payload: any, appendUrl?: string): Observable<any> {
    let finalUrl = `${this.baseUrl}/${id}`;
    if (appendUrl) {
      finalUrl = `${finalUrl}/${appendUrl}`;
    }
    return this.httpClient.put(finalUrl, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  delete(id: string, appendUrl?: string): Observable<any> {
    let finalUrl = `${this.baseUrl}/${id}`;
    if (appendUrl) {
      finalUrl = `${finalUrl}/${appendUrl}`;
    }
    return this.httpClient.delete(finalUrl).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteAll(): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/all`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
