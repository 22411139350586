import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private _httpClient: HttpClient) {}

  getAgentUsers(agentId: string) {
    return this._httpClient.get(`/agent/${agentId}/users`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getUsers(userId: string) {
    return this._httpClient.get(`/user/${userId}/users`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getFilterUsers(userId: string, payload: any) {
    return this._httpClient.put(`/user/${userId}/users`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getUserDetails(userId: string) {
    return this._httpClient.get(`/user/${userId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getUserDetail(agentId: string, userId: string) {
    return this._httpClient.get(`/agent/${agentId}/user/${userId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
  //todo - remove later
  createAgentUser(agentId: string, payload: any) {
    return this._httpClient.post(`/agent/${agentId}/user`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  createUser(userId: string, payload: any) {
    return this._httpClient.post(`/agent/${userId}/user`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateAgentUser(agentId: string, userId: string, payload: any) {
    return this._httpClient
      .put(`/agent/${agentId}/user/${userId}`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  deleteAgentUser(agentId: string, userId: string) {
    return this._httpClient.delete(`/agent/${agentId}/user/${userId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getUserRoles() {
    return this._httpClient.get(`/getuserroles`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  sendActivationCode(userId: String) {
    return this._httpClient.get(`/agent/${userId}/sendcode`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  //audit
  getAuditActions() {
    return this._httpClient.get(`/auditactions`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAllAudits(payload: any) {
    return this._httpClient.get(`/audit`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAllAuditsByFilter(payload: any) {
    let params = payload.customerId
      ? new HttpParams().set('customerId', `${payload.customerId}`)
      : new HttpParams();
    params = payload.datetime_from
      ? params.append('datetime_from', `${payload.datetime_from}`)
      : params;
    params = payload.datetime_to
      ? params.append('datetime_to', `${payload.datetime_to}`)
      : params;
    params = payload.rid
      ? params.append('rId', `${payload.rid}`)
      : params;
    params = payload.type
      ? params.append('type', `${payload.type}`)
      : params;
    params = payload.cid
      ? params.append('cid', `${payload.cid}`)
      : params;
      params = payload.email
      ? params.append('email', `${payload.email}`)
      : params;
    params = payload.sortFieldFilter
      ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
      : params;
    params = payload.sortByFilter
      ? params.append('sortByFilter', `${payload.sortByFilter}`)
      : params;
    params = params.append('currentPage', `${payload.currentPage}`);
    params = params.append('perPage', `${payload.perPage}`);
    return this._httpClient.get('/audit', { params }).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getIpdetails(ip:any){
    return this._httpClient.get(`/ipdetails/${ip}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }


}
