import { Component, OnInit } from '@angular/core';
import { DealsService } from '@app/core/service/deals.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  isLoading = false;
  user: Authentication.User;
  dealsReport: any = <any>{};
  overAllDealData = { 'alldealcount': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0 };
  keyfetchDealData = { 'alldealcount': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0 };
  DOWCDealData = { 'alldealcount': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0 };
  ScMergeDealData = { 'alldealcount': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0 };
  SCCOMDealData = { 'alldealcount': 0, 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0 };
  AgencyDealDataList: any = [];
  UserDealDataList: any = [];


  public pieChartLabels: string[] = ["Direct", "DOWC", "Servicecontract", "DOWC & Servicecontract","Keyfetch"];
  public pieChartData: number[] = [];
  public pieChartType = 'pie';
  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };
  constructor(
    private dealsService: DealsService,
    private authenticationService: AuthenticationService,
  ) {
    this.user = this.authenticationService.credentials.user;
    this.getAllDealReport();


  }

  ngOnInit() {
  }
  getAllDealReport() {
    this.isLoading = true;
    //todo - agent remove id
    this.dealsService
      .getAnalyticsDealData(this.user._id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        // console.log(response);
        if (response) {
          this.dealsReport = response;
          // console.log(this.dealsReport.Overall);
          //over all deal data - begin
          this.dealsReport.Overall.forEach((val: any) => {
            let count = val.count;
            this.overAllDealData.alldealcount = this.overAllDealData.alldealcount + count;
            switch (val._id) {
              case 'in review':
                this.overAllDealData.inreview = count;
                break;
              case 'rejected':
                this.overAllDealData.rejected = count;
                break;
              case 'in progress':
                this.overAllDealData.inprogress = count;
                break;
              case 'approved':
                this.overAllDealData.approved = count;
                break;
            }

            // console.log(val._id,'-');
          });
          // over all deal data - end

          // keyfetch deals data - begin

          this.dealsReport.keyfetch.forEach((val: any) => {
            let count = val.count;
            this.keyfetchDealData.alldealcount = this.keyfetchDealData.alldealcount + count;
            switch (val._id) {
              case 'in review':
                this.keyfetchDealData.inreview = count;
                break;
              case 'rejected':
                this.keyfetchDealData.rejected = count;
                break;
              case 'in progress':
                this.keyfetchDealData.inprogress = count;
                break;
              case 'approved':
                this.keyfetchDealData.approved = count;
                break;
            }

            // console.log(val._id,'-');
          });
          // keyfetch deals data - end
          // DOWC deals data - begin

          this.dealsReport.DOWC.forEach((val: any) => {
            let count = val.count;
            this.DOWCDealData.alldealcount = this.DOWCDealData.alldealcount + count;
            switch (val._id) {
              case 'in review':
                this.DOWCDealData.inreview = count;
                break;
              case 'rejected':
                this.DOWCDealData.rejected = count;
                break;
              case 'in progress':
                this.DOWCDealData.inprogress = count;
                break;
              case 'approved':
                this.DOWCDealData.approved = count;
                break;
            }

            // console.log(val._id,'-');
          });
          // DOWC deals data - end
          // Scmerge deals data - begin

          this.dealsReport.ScMerge.forEach((val: any) => {
            let count = val.count;
            this.ScMergeDealData.alldealcount = this.ScMergeDealData.alldealcount + count;
            switch (val._id) {
              case 'in review':
                this.ScMergeDealData.inreview = count;
                break;
              case 'rejected':
                this.ScMergeDealData.rejected = count;
                break;
              case 'in progress':
                this.ScMergeDealData.inprogress = count;
                break;
              case 'approved':
                this.ScMergeDealData.approved = count;
                break;
            }

            console.log(val._id, '-');
          });
          // Scmerge deals data - end
          // SCCOM deals data - begin

          this.dealsReport.SCCOM.forEach((val: any) => {
            let count = val.count;
            this.SCCOMDealData.alldealcount = this.SCCOMDealData.alldealcount + count;
            switch (val._id) {
              case 'in review':
                this.SCCOMDealData.inreview = count;
                break;
              case 'rejected':
                this.SCCOMDealData.rejected = count;
                break;
              case 'in progress':
                this.SCCOMDealData.inprogress = count;
                break;
              case 'approved':
                this.SCCOMDealData.approved = count;
                break;
            }

            // console.log(val._id, '-');
          });

          // SCCOM deals data - end
          //agencydata - begin
          this.dealsReport.agencydata.forEach((val: any) => {
            let AgencyDealData = { 'name': '', 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'count': 0 };
            // console.log(val.agencyDetail.length,'countttttttttt')
            if (val.agencyDetail.length > 0) {

              // console.log(val.count,'----',val.agencyDetail[0].agencyname,'----')
              AgencyDealData.name = val.agencyDetail[0].agencyname;
              AgencyDealData.count = val.count;
              val.status.forEach((val1: any) => {
                switch (val1._id) {
                  case 'in review':
                    AgencyDealData.inreview = val1.count;
                    break;
                  case 'rejected':
                    AgencyDealData.rejected = val1.count;
                    break;
                  case 'in progress':
                    AgencyDealData.inprogress = val1.count;
                    break;
                  case 'approved':
                    AgencyDealData.approved = val1.count;
                    break;
                }
              });
              this.AgencyDealDataList.push(AgencyDealData);
            }

          });
          // console.log(this.AgencyDealDataList, 'AgencyDealData------')
          // agencydata - end

          // userdeal data - begin
          this.dealsReport.userwiseData.forEach((val: any) => {
            let userDealData = { 'name': '', 'inprogress': 0, 'inreview': 0, 'approved': 0, 'rejected': 0, 'count': 0 };
            // console.log(val.agencyDetail.length,'countttttttttt')
            if (val.userDetail.length > 0) {

              // console.log(val.count,'----',val.agencyDetail[0].agencyname,'----')
              if(val.userDetail[0].lastName)
              {
                userDealData.name = val.userDetail[0].lastName;
              }
              else{
                if(val.userDetail[0].firstName){
                  userDealData.name = val.userDetail[0].firstName;
                }
                else{
                  let emailname = val.userDetail[0].email.split("@")
                  userDealData.name = emailname[0];
                }
              }
              
              userDealData.count = val.count;
              val.status.forEach((val1: any) => {
                switch (val1._id) {
                  case 'in review':
                    userDealData.inreview = val1.count;
                    break;
                  case 'rejected':
                    userDealData.rejected = val1.count;
                    break;
                  case 'in progress':
                    userDealData.inprogress = val1.count;
                    break;
                  case 'approved':
                    userDealData.approved = val1.count;
                    break;
                }
              });
              this.UserDealDataList.push(userDealData);
            }

          });
          // console.log(this.UserDealDataList, 'UserDealDataList------')          
          // userdeal data - end
          var directdealcount = this.overAllDealData.alldealcount - (this.keyfetchDealData.alldealcount + this.DOWCDealData.alldealcount + this.ScMergeDealData.alldealcount + this.SCCOMDealData.alldealcount)
          this.pieChartData = [directdealcount, this.DOWCDealData.alldealcount,this.SCCOMDealData.alldealcount , this.ScMergeDealData.alldealcount, this.keyfetchDealData.alldealcount];
          // console.log(this.pieChartData);

          // this.deals = [];
        }
      });

  }
}
