import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { matchValidation } from '@app/core';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordService } from '@app/core/service/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  loggedInUserType: any = null;
  changePasswordForm: FormGroup;
  isLoading = false;
  queryParams: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private changePasswordService: ChangePasswordService
  ) {
    this.loggedInUserType = localStorage.getItem('userType');
  }

  ngOnInit() {
    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
      this.createForm();
    });
  }

  private createForm() {
    this.changePasswordForm = this.formBuilder.group(
      {
        currentpassword: ['', [Validators.required]],
        newpassword: ['', [Validators.required]],
        confirmpassword: ['', [Validators.required]]
      },
      { validator: matchValidation('newpassword', 'confirmpassword') }
    );
  }

  changePassword() {
    this.isLoading = true;
    let payload: any;

    payload = {
      oPassword: this.changePasswordForm.value.currentpassword,
      password: this.changePasswordForm.value.newpassword,
      cPassword: this.changePasswordForm.value.confirmpassword
    };

    this.changePasswordService
      .updatePassword(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (response: any) => {
          this.toastrService.info('Password Updated Successfully');
          this.router.navigate(['/profile']);
        },
        (error: any) => {
          console.log(
            'Confirmation modal error:',
            error.error.validation.errors
          );
          this.isLoading = false;
        }
      );
  }
}
