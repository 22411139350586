import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  constructor(protected httpClient: HttpClient) {}
  //todo - remove later
  getAgents() {
    return this.httpClient.get(`/agents`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
  //todo - remove later
  getAgentDetail(agentId: String) {
    return this.httpClient.get(`/agent/${agentId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
  getAgencyDetail(agencyId: String) {
    return this.httpClient.get(`/agent/${agencyId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
  createAgency(payload: any) {
    return this.httpClient.post(`/agency`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAgencyList() {
    return this.httpClient.get(`/getagencylist`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getSubAgencies(agentId: any) {
    return this.httpClient.get(`/agent/${agentId}/subagency`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAgencyUser() {
    return this.httpClient.get(`/getagencyusers`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateAgent(agentId: String, payload: any) {
    return this.httpClient.put(`/agent/${agentId}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteAgent(agentId: String) {
    return this.httpClient.delete(`/agent/${agentId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAgencyFilterList(payload: any) {
    return this.httpClient.post(`/getagencyfilterlist`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
